<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Guia Remisión - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Datos de Guia de Remisión" active>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Comprobante :">
                              <b-form-select @change="ListSeries" v-model="referral_guide.type_invoice" :options="type_invoice"></b-form-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Serie :">
                              <b-form-select @change="GetNumberBySerie" v-model="referral_guide.id_serie" :options="series"></b-form-select>
                              <small v-if="errors.id_serie"  class="form-text text-danger" >Seleccione una serie</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Numero :">
                              <b-form-input class="text-center" readonly type="text" ref="number"  v-model="referral_guide.number"></b-form-input>
                              <small v-if="errors.number" class="form-text text-danger">Ingrese un numero de 8 digitos</small>
                            </b-form-group>
                          </b-col>
                          

                          <b-col md="2">
                            <b-form-group label="Fecha Emision:">
                              <b-form-input class="text-center" type="date" :max="referral_guide.date_now" v-model="referral_guide.broadcast_date"></b-form-input>
                              <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group :label="label_transfer">
                              <b-form-input class="text-center" type="date" :max="referral_guide.date_now" v-model="referral_guide.transfer_date"></b-form-input>
                              <small v-if="errors.transfer_date" class="form-text text-danger">Seleccione una fecha</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label=".">
                              <b-button class="form-control" variant="primary" @click="modalProducts"><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group label="Motivo:">
                              <b-form-select @change="ChangeReason" v-model="referral_guide.reason_code" :options="reason_code"></b-form-select>
                              <small v-if="errors.reason_code"  class="form-text text-danger" >Seleccione un motivo</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="6" v-if="referral_guide.reason_code == '02' || referral_guide.reason_code == '04' || referral_guide.reason_code == '13' || referral_guide.reason_code == '06'">
                            <b-form-group>
                              <label>Proveedor: <span @click="modalProviders" class="text-info link">Nuevo</span></label>
                              <v-select @input="GetEntityInformation" placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="entity" @search="SearchEntities" :options="entities"></v-select>
                              <small v-if="errors.id_entity" class="form-text text-danger" >Seleccione un proveedor</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="6" v-if="referral_guide.reason_code == '01' || referral_guide.reason_code == '14'">
                            <b-form-group>
                              <label>Cliente: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                              <v-select @input="GetEntityInformation" placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="entity" @search="SearchEntities" :options="entities"></v-select>
                              <small v-if="errors.id_entity" class="form-text text-danger" >Seleccione un cliente</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="6" v-if="referral_guide.reason_code == ''">
                            <b-form-group>
                              <label>Cliente: </label>
                              <v-select disabled  placeholder="Seleccione un motivo" class="w-100" :filterable="false" label="name"></v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                             <b-form-group label=".">
                              <b-button @click="ModalRelatedDocumentsShow" type="button" class="form-control text-white" variant="info" >Doc. Relacionados</b-button>
                            </b-form-group>
                          </b-col>


                          <b-col md="4">
                            <b-form-group label="Ubigeo de Partida:" required>
                            <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100"  v-model="ubigee_departure" :options="ubigees"></v-select>
                            <small v-if="errors.ubigee_departure"  class="form-text text-danger" >seleccion un ubigeo</small>
                            </b-form-group>
                          </b-col>
                          
                          <b-col md="6">
                            <b-form-group label="Dirección de Partida:">
                              <b-form-input v-model="referral_guide.address_departure"></b-form-input>
                              <small v-if="errors.address_departure"  class="form-text text-danger" >Ingrese una dirección</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Cod. Estab. Partida:">
                              <b-form-input rows="1"  v-model="referral_guide.code_departure"></b-form-input>
                              <small v-if="errors.code_departure"  class="form-text text-danger" >Ingrese un codigo de establecimiento</small>
                            </b-form-group>
                          </b-col>

                           <b-col md="4">
                            <b-form-group label="Ubigeo de Llegada:" required>
                            <v-select placeholder="Seleccione un ubigeo" label="text" class="w-100"  v-model="ubigee_destination" :options="ubigees"></v-select>
                            <small v-if="errors.ubigee_destination"  class="form-text text-danger" >seleccion un ubigeo</small>
                            </b-form-group>
                          </b-col>
                          
                          <b-col md="6">
                            <b-form-group label="Dirección de Llegada:">
                              <b-form-input v-model="referral_guide.address_destination"></b-form-input>
                              <small v-if="errors.address_destination"  class="form-text text-danger" >Ingrese una dirección</small>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group label="Cod. Estab. Llegada:">
                              <b-form-input rows="1"  v-model="referral_guide.code_destination"></b-form-input>
                              <small v-if="errors.code_destination"  class="form-text text-danger" >Ingrese un codigo de establecimiento</small>
                            </b-form-group>
                          </b-col>
 
                          <!-- Detalle venta -->
                          <ReferralGuideDetail type="New"/>
                          <b-col md="12" class="text-center">
                            <small  v-if="errors.referral_guide_detail"  class="form-text tex-center text-danger">Ingrese el detalle de la guia de remisión</small>
                          </b-col>

                          <!-- Detalle venta -->

                          <b-col md="12" class="mt-3"></b-col>
                          
                          
                          <b-col md="5">
                             <b-form-group label="Observación:">
                              <b-form-textarea rows="3"  v-model="referral_guide.observation" max-rows="2"></b-form-textarea>
                            </b-form-group>
                          </b-col>
                          <b-col md="1"></b-col>
                          <b-col md="3">
                            <div class="table-responsive mt-1">
                                <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                                  <thead >
                                    <tr>
                                      <th  width="85%" class="text-center">Comprobante</th>
                                      <th  width="15%" class="text-center">Acc.</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="(item, it) in referral_guide.linkages" :key="it">
                                    <tr>
                                      <td class="text-center">{{ CodeInvoice(item.type_invoice) + " " + item.serie + " " + item.number  }}</td>
                                      <td class="text-center">
                                        <button type="button" @click="DeleteLinkages(it)" class="btn btn-danger">
                                          <i class="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                            <small v-if="errors.linkages"  class="form-text text-danger" >Ingrese un documento</small>
                          </b-col>
                          <b-col md="1"></b-col>
                          <b-col md="2">
                            <b-form-group label="U.M. de Peso Bruto:">
                              <b-form-select :options="unit_measure_weight" v-model="referral_guide.unit_measure_weight"></b-form-select>
                              <small v-if="errors.unit_measure_weight"  class="form-text text-danger" >ingrese el peso</small>
                            </b-form-group>
                            <b-form-group label="Peso Bruto Total:">
                              <b-form-input type="number" step="any" class="text-right" v-model="referral_guide.total_weight"></b-form-input>
                              <small v-if="errors.total_weight"  class="form-text text-danger" >ingrese el peso</small>
                            </b-form-group>
                          </b-col>
                      
                            
  

                          <b-col md="5"></b-col>
                          <b-col md="2">
                            <b-button  type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                          </b-col>
                        </b-row>
                </b-tab>
                <b-tab title="Datos de Transportista">
                  <b-row>
                    <b-col md="2">
                      <b-form-group label="Medio de Transporte">
                        <b-form-select v-model="referral_guide.carrier_type" :options="carrier_type"></b-form-select>
                        <small v-if="errors.carrier_type"  class="form-text text-danger" >Seleccione un tipo</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="10" v-if="referral_guide.carrier_type =='01'  && referral_guide.transfer_vehicle == 0">
                      <b-form-group>
                        <label>Transportista: <span @click="modalProviders" class="text-info link">Nuevo</span></label>
                        <v-select placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="carrier" @search="SearchCarrier" :options="carriers"></v-select>
                        <small v-if="errors.id_carrier" class="form-text text-danger" >Seleccione un proveedor</small>
                      </b-form-group>
                    </b-col>
                    
                  

                    <b-col md="4" v-if="referral_guide.carrier_type =='02'  && referral_guide.transfer_vehicle == 0">
                      <b-form-group>
                        <label>Vehiculo : <span @click="modalCars" class="text-info link">Nuevo</span></label>
                        <v-select placeholder="Seleccione un vehiculo" label="text" class="w-100"  v-model="car" :options="cars"></v-select>
                        <small v-if="errors.id_car" class="form-text text-danger" >Seleccione un vehiculo</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" v-if="referral_guide.carrier_type =='02' && referral_guide.transfer_vehicle == 0">
                      <b-form-group>
                        <label>Conductor : <span @click="modalDrives" class="text-info link">Nuevo</span></label>
                        <v-select placeholder="Seleccione un conductor" label="text" class="w-100"  v-model="drive" :options="drives"></v-select>
                        <small v-if="errors.id_drive" class="form-text text-danger" >Seleccione un conductor</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="2" v-if="referral_guide.transfer_vehicle == 1">
                      <b-form-group>
                        <label>Placa : </label>
                        <b-form-input v-model="referral_guide.license_plate_vehicle"></b-form-input>
                        <small v-if="errors.license_plate_vehicle" class="form-text text-danger" >Ingrese una placa</small>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label=".">
                        <b-form-checkbox  v-model="referral_guide.scheduled_transhipment" value="1" unchecked-value="0">
                          Realiza transbordo programado
                        </b-form-checkbox>
                        <b-form-checkbox  v-model="referral_guide.transfer_vehicle" value="1" unchecked-value="0">
                          Traslado en vehículos de categoría M1 o L
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <b-col md="12"></b-col>
                    <b-col md="5"></b-col>
                    <b-col md="2">
                      <b-button  type="submit" class="form-control text-white" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                    </b-col>
                    
                  </b-row>
                  </b-tab>
              </b-tabs>




            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Products -->

    <ModalCar />
    <ModalDrive />
    <ModalProviders />
    <ModalClient />
    <ModalRelatedDocuments/>
    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>

</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import converter from "@/assets/js/NumberToLetters";
import { mapState,mapActions } from "vuex";
import CodeToName from "@/assets/js/CodeToName";

// components
import ModalProviders from '@/views/components/ModalProvider'
import ModalClient from '@/views/components/ModalClient'
import LoadingComponent from './../pages/Loading'

import ModalCar from './components/ModalCar'
import ModalDrive from './components/ModalDrive'
import ModalProducts from './components/ModalProduct'
import ReferralGuideDetail from './components/ReferralGuideDetail'
import ModalRelatedDocuments from './components/ModalRelatedDocuments'

export default {
  name: "UsuarioAdd",
  components:{
      vSelect,  
      ModalProducts,
      ReferralGuideDetail,
      ModalProviders,
      LoadingComponent,
      ModalCar,
      ModalDrive,
      ModalRelatedDocuments,
      ModalClient,
  },
  data() {
    return {
      isLoading:false,
      module: 'ReferralGuide',
      role: 2,
      label_transfer:'Fecha Traslado',
      referral_guide: {
        type_serie:'',
        id_referral_guide: "",
        id_user: "",
        id_serie: "",
        id_establishment: "",
        entity_type: "",
        id_entity:"",
        document_type:"",
        document_number:"",
        name:"",
        type_invoice: "09",
        serie: "",
        number: "",
        date_now: moment(new Date()).local().format("YYYY-MM-DD"),
        broadcast_date: moment(new Date()).local().format("YYYY-MM-DD"),
        broadcast_time: "",
        transfer_date: moment(new Date()).local().format("YYYY-MM-DD"),
        reason_code: "",
        reason: "",
        note: "",

        ubigee_destination:"",
        address_destination:"",
        code_destination:"",
        ubigee_departure:"",
        address_departure:"",
        code_departure:"",
        
        unit_measure_weight: "KGM",
        total_weight: 0,
        number_packages: 0,
        carrier_type: "01",
        id_carrier: 0,
        id_car: 0,
        id_drive: 0,
        scheduled_transhipment:0,
        transfer_vehicle:0,
        license_plate_vehicle:'',

        code_hash: "",
        code_cdr: "",
        sunat_message: "",
        observation: "",
        state: '1',
        linkages: [],
      },
      unit_measure_weight:[
        {value:'KGM',text:'KILOGRAMO'},
        {value:'TNE',text:'TONELADAS'},
      ],

      entities: [],
      entity:null,

      // providers: [],
      // provider: null,
      carriers: [],
      carrier:null,
      ubigees:[],
      ubigee_destination:null,
      ubigee_departure:null,
      cars:[],
      car:null,
      drives:[],
      drive:null,
      series: null,
      establishments: [],

      data_establishment:{
        ubigee:null,
        address:'',
        code:'',
      },

      type_invoice:[
        {value: "09", text : "Guia de Remisión"},
      ],
      carrier_type:[
        {value:'02', text:'Privado'},
        {value:'01', text:'Publico'},
      ],


       reason_code:[

        {value:'',  text:	'-Seleccione-'},
        {value:'01',text:	'Venta'},
        {value:'02',text:	'Compra'},
        {value:'06',text:	'Devolución'},
        {value:'04',text:	'Traslado entre establecimientos de la misma empresa'},
        // {value:'08',text:	'Importación'},
        // {value:'09',text:	'Exportación'},
        {value:'13',text:	'Otros'},
        {value:'14',text:	'Venta sujeta a confirmación del comprador   '},
        // {value:'18',text:	'Traslado emisor itinerante CP'},
        // {value:'19',text:	'Traslado a zona primaria'},
      ],
      //errors
      errors: {
        id_entity:false,
        broadcast_date:false,
        transfer_date: false,
        ubigee_destination: false,
        address_destination: false,
        code_destination: false,

        ubigee_departure:false,
        address_departure:false,
        code_departure:false,

        unit_measure_weight:false,
        total_weight: false,
        number_packages: false,
        carrier_type: false,
        id_carrier: false,
        id_car: false,
        id_drive: false,
        referral_guide_detail: false,
        reason_code:false,
        license_plate_vehicle:false,
        linkages: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.mLoadResetReferralGuideDetail();
    this.ListSeries();
    this.ListUbigeos();
    this.ListCars();
    this.ListDrives();
    this.ViewEstablishment();
    // EventBus.$on('RefreshListDrives', () => {
    //   this.ListDrives();
    // });
    // EventBus.$on('RefreshListCars', () => {
    //   this.ListCars();
    // });

    EventBus.$on('AddRelatedDocuments', (related_documents) => {
      this.referral_guide.linkages.push(related_documents);
    });
    EventBus.$on('MTotalWeightTotalProducts', (total_weight) => {
      this.referral_guide.total_weight = total_weight;
    });
  },
  methods: {
    ViewEstablishment,
    ListSeries,
    ListUbigeos,
    GetNumberBySerie,
    modalProducts,
    AddReferralGuide,
    Validate,
    SearchEntities,
    ListCars,
    ListDrives,

    ChangeReason,
    modalProviders,
    modalClients,
    modalCars,
    modalDrives,

    ModalRelatedDocumentsShow,
    CodeInvoice,
    DeleteLinkages,
    GetEntityInformation,
    SearchCarrier,

    DataPrint,
    Print,

    ...mapActions('ReferralGuide',['mLoadResetReferralGuideDetail']),
  },

  computed: {
    ...mapState('ReferralGuide',['referral_guide_detail','total_weight']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};
function ViewEstablishment() {

  let url = this.url_base + "establishment/view/"+this.id_establishment;
  let me = this;
  axios({
      method: "GET",
      url: url,
      headers: { token: this.token, module: this.module, role: this.role, },
    }).then(function (response) {
      if (response.data.status == 200){
        me.data_establishment.code = response.data.result.code;
        me.data_establishment.address = response.data.result.address; 
        me.data_establishment.ubigee = response.data.result.data_ubigee;
      }
    })
}

function ChangeReason() {
  this.entity = null;
  this.entities = [];

  this.referral_guide.address_destination = '';
  this.ubigee_destination = null;
  this.referral_guide.code_destination = '';

  this.referral_guide.address_departure = '';
  this.ubigee_departure = null;
  this.referral_guide.code_departure = '';

  if (this.referral_guide.reason_code == '02') {
    this.label_transfer = 'Fecha Entrega';
  }else{
    this.label_transfer = 'Fecha Traslado';
  }

}

function SearchCarrier(search, loading) {
    let me = this;
    let url = this.url_base + "search-carries/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.carriers = response.data.result;
            loading(false);
      })
    }
}


function GetEntityInformation() {
  let me = this;
  if (this.entity != null) {
      if (this.referral_guide.reason_code == "01" || this.referral_guide.reason_code == "14") {
          me.referral_guide.address_destination = this.entity.address;
          me.ubigee_destination = this.entity.ubigee;
          me.referral_guide.code_destination = '';

          me.referral_guide.address_departure = this.data_establishment.address;
          me.ubigee_departure = this.data_establishment.ubigee;
          me.referral_guide.code_departure = this.data_establishment.code;

          me.referral_guide.entity_type = this.entity.entity_type;
          me.referral_guide.id_entity = this.entity.id;
          
      }else{
          me.referral_guide.address_departure = this.entity.address;
          me.ubigee_departure = this.entity.ubigee;
          me.referral_guide.code_departure = '';

          me.referral_guide.address_destination = this.data_establishment.address;
          me.ubigee_destination = this.data_establishment.ubigee;
          me.referral_guide.code_destination = this.data_establishment.code;
          
          me.referral_guide.entity_type = this.entity.entity_type;
          me.referral_guide.id_entity = this.entity.id;
      }
  }else{
      if (this.referral_guide.reason_code == "01" || this.referral_guide.reason_code == "14") {
          me.referral_guide.address_destination = '';
          me.ubigee_destination = null;
          me.referral_guide.code_destination = '';

          me.referral_guide.address_departure = this.data_establishment.address;
          me.ubigee_departure = this.data_establishment.data_ubigee;
          me.referral_guide.code_departure = this.data_establishment.code;
      }else{
          me.referral_guide.address_departure = '';
          me.ubigee_departure = null;
          me.referral_guide.code_departure = '';

          me.referral_guide.address_destination = this.data_establishment.address;
          me.ubigee_destination = this.data_establishment.data_ubigee;
          me.referral_guide.code_destination = this.data_establishment.code;
      }
  }
}

function DeleteLinkages(index) {
    this.referral_guide.linkages.splice(index, 1);
}

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ListUbigeos() {
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigees.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
    })

}

function ListCars() {
  let url = this.url_base + "car/list-active";
  let me = this;
  axios({
      method: "GET",
      url: url,
      headers: { token: this.token, module: this.module, role: this.role, },
    }).then(function (response) {
      me.cars = [];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.cars.push({id:element.id_car,text:element.brand + " | "+ element.plate + " | " + element.plate_secondary + " | " + element.record_number})
      }
    })
}
function ListDrives() {
  let url = this.url_base + "drive/list-active";
  let me = this;
  axios({
      method: "GET",
      url: url,
      headers: { token: this.token, module: this.module, role: this.role, },
    }).then(function (response) {
      me.drives = [];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.drives.push({id:element.id_drive,text:element.name + " | "+ element.document_number + " | " + element.license_number})
      }
    })
}

function SearchEntities(search, loading) {
    let me = this;
    let type = '';
    if (this.referral_guide.reason_code == "01" || this.referral_guide.reason_code == "14") {
      type = 'Client';
    }else{
      type = 'Provider';
    }
    let url = this.url_base + "search-entity/"+type+"/"+search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.entities = response.data.result;
            loading(false);
      })
    }
}

function modalProviders() {
  EventBus.$emit('ModalProvidersShow');
}
function modalClients() {
  EventBus.$emit('ModalClientsShow');
}


function modalCars() {
  EventBus.$emit('ModalCarsShow',2);
}
function modalDrives() {
  EventBus.$emit('ModalDrivesShow',2);
}
function ModalRelatedDocumentsShow() {
  EventBus.$emit('ModalRelatedDocumentsShow',2);
}

//listar almacenes
function ListSeries() {

  let me = this;
  let url = this.url_base + "list-series/"+this.referral_guide.type_invoice+"/"+this.id_establishment;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
    .then(function (response) {
      me.series = [];
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.series.push( { value : data[index].id_serie , text: data[index].serie } );
          me.referral_guide.id_serie = data[index].id_serie;
          me.referral_guide.type_serie = data[index].document_type;
        }
        
         me.GetNumberBySerie();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function GetNumberBySerie() {
   let me = this;
  let url = this.url_base + "serie/view/"+this.referral_guide.id_serie;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.referral_guide.number = response.data.result.number;
      } else {
        me.referral_guide.number = '';
      }
    })
}

function modalProducts() {
  // if (this.referral_guide.id_establishment_request.length == 0) {
  //   Swal.fire({ icon: 'warning', text: 'Seleccione un establecimiento', timer: 2000,})
  //   return false;
  // }
  EventBus.$emit('ModalProductsShow',this.role);
}


function AddReferralGuide() {
  let me = this;
  let url = me.url_base + "referralguide/add";
  me.referral_guide.id_user = me.user.id_user;
  me.referral_guide.id_establishment = me.id_establishment;
  me.referral_guide.id_entity = me.entity.id;
  me.referral_guide.ubigee_departure = me.ubigee_departure.value;
  me.referral_guide.ubigee_destination = me.ubigee_destination.value;
  me.referral_guide.id_carrier = me.carrier == null ? '' : me.carrier.id;
  me.referral_guide.id_car = me.car == null ? '' : me.car.id;
  me.referral_guide.id_drive = me.drive == null ? '' : me.drive.id;
  // me.referral_guide.total_weight = me.total_weight;
  me.referral_guide.referral_guide_detail = me.referral_guide_detail;
  let data = me.referral_guide;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.DataPrint(response.data.result.id_referral_guide);
        Swal.fire({ icon: 'success', text: 'Se ha registrado la guia de remisión', timer: 3000,})
        me.$router.push({
        name: "ReferralGuideList"
       });
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  

  this.errors.id_entity = this.entity == null ? true : false;
  this.errors.broadcast_date = this.referral_guide.broadcast_date.length == 0 ? true : false;
  this.errors.transfer_date = this.referral_guide.transfer_date.length == 0 ? true : false;
  this.errors.ubigee_destination = this.ubigee_destination == null ? true : false;
  this.errors.address_destination = this.referral_guide.address_destination.length == 0 ? true : false;
  // this.errors.code_destination = this.referral_guide.code_destination.length == 0 ? true : false;
  this.errors.ubigee_departure = this.ubigee_departure == null ? true : false;
  this.errors.address_departure = this.referral_guide.address_departure.length == 0 ? true : false;
  // this.errors.code_departure = this.referral_guide.code_departure.length == 0 ? true : false;
  this.errors.referral_guide_detail = this.referral_guide_detail.length == 0 ? true : false;
  this.errors.reason_code = this.referral_guide.reason_code.length == 0 ? true : false;

  if (this.referral_guide.reason_code == "02") {
     this.errors.linkages = this.referral_guide.linkages.length == 0 ? true : false;
  }
 

  
 if (this.referral_guide.reason_code == "01" || this.referral_guide.reason_code == "14" || this.referral_guide.reason_code == "06") {
    this.errors.code_departure = this.referral_guide.code_departure.length == 0 ? true : false;
  }else{
    this.errors.code_destination = this.referral_guide.code_destination.length == 0 ? true : false;
  }

  this.errors.total_weight = false;
  this.errors.number_packages = false;
  this.errors.id_car = false;
  this.errors.id_drive = false;
  this.errors.id_carrier = false;
  this.errors.license_plate_vehicle = false;
  this.errors.transfer_vehicle = false;
  
  this.errors.total_weight = this.referral_guide.total_weight.length == 0 || parseFloat(this.referral_guide.total_weight) == 0 ? true : false;
  // this.errors.number_packages = parseFloat(this.referral_guide.number_packages) == 0 ? true : false;
  this.errors.carrier_type = this.referral_guide.carrier_type.length == 0 ? true : false;
  if (this.referral_guide.carrier_type == "02") {
    if (this.referral_guide.transfer_vehicle == 1) {
      this.errors.license_plate_vehicle = this.referral_guide.license_plate_vehicle.length == 0 ? true : false;
    }else{
      this.errors.id_car = this.car == null ? true : false;
      this.errors.id_drive = this.drive == null ? true : false;
    }
  }else{
    if (this.referral_guide.transfer_vehicle == 1) {
      this.errors.license_plate_vehicle = this.referral_guide.license_plate_vehicle.length == 0 ? true : false;
    }else{
      this.errors.id_carrier = this.carrier == null ? true : false;
    }
  }
  

  
  if (this.errors.id_entity == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.broadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.transfer_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.ubigee_departure == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.address_departure == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code_departure == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.ubigee_destination == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.address_destination == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code_destination == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.reason_code == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.total_weight == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.number_packages == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.carrier_type == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_carrier == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_car == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_drive == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.referral_guide_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.license_plate_vehicle == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.linkages == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  Swal.fire({
    title: 'Esta seguro de completar la guia?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.AddReferralGuide();
    }
  })



}

function DataPrint(id_referral_guide) {
  let me = this;
  if (this.type_print == 1) {
    let url = me.url_base + "referralguide/data-print/"+id_referral_guide;
    let data = me.sale;
    axios({
      method: "GET",
      url: url,
      data: data,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
    })
    .then(function (response) {
      if (response.data.status == 200) {
        me.Print(response.data.result);
      } 
    })
  }
  if (this.type_print == 2) {
    let url = this.url_base + 'referral-guide-pdf/'+id_referral_guide;
    window.open(url,'_blank');
  }
  
}
function Print(info) {
  let url = 'http://localhost/print/consumirapi-referral-guide.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}



</script>
